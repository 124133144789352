import { clsx, type ClassValue } from 'clsx'
import humanizeDuration from 'humanize-duration'
import { phone } from 'phone'
import { twMerge } from 'tailwind-merge'
import { isEmail } from 'validator'

import { Form } from '../../@types/Form'
import { API_TARGET } from '../config'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export async function apiFetch(path = '', options = {} as { json?: object } & RequestInit) {
    const response = await fetch(`${API_TARGET}${path}`, {
        body: options.json ? JSON.stringify(options.json) : undefined,
        credentials: 'include',
        ...options,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...(options.headers || {}),
        },
    })
    return { json: await response.json(), status: response.status }
}

export const humanize = (date: number) => {
    return humanizeDuration(Date.now() - date, {
        round: true,
        units: ['y', 'mo', 'w', 'd', 'h', 'm'],
    })
}

export const sendForm = async (form: Form) => {
    const emailOrNumber = prompt('Enter email or number')
    if (!emailOrNumber) return

    let email
    if (isEmail(emailOrNumber)) {
        email = emailOrNumber
    }

    let phoneNumber
    const phoneNumberParsed = phone(emailOrNumber)
    if (phoneNumberParsed.isValid) {
        phoneNumber = phoneNumberParsed.phoneNumber
    }

    if (!email && !phoneNumber) {
        alert('Invalid email or phone number')
        return
    }

    console.log('Sending form', form.id, email, phoneNumber)

    apiFetch(`/send/${form.id}`, {
        method: 'POST',
        json: { email, phoneNumber },
    }).then(({ status }) => {
        if (status === 202) {
            alert('Sent!')
        } else {
            alert(`Failed to send: status ${status}`)
        }
    })
}
